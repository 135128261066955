<template>
  <div :class="'landing-preloader' + (started ? ' _started' : '')">
    <div class="preloader__video-wrapper">
      <video class="preloader__video preloader__video--center" loop muted playsinline="playsinline" autoplay="autoplay" ref="video" @canplaythrough="videoReadyHandler"></video>
    </div>
  </div>
</template>

<script>
import ResourceSelector from '../utils/ResourceSelector'

export default {
  name: "preloader",
  data() {
    return {
      started: false,
      hided: false
    }
  },
  mounted() {
    this.$refs.video.src = `/preloader/${ResourceSelector.videoSize}_preloader.mp4`
  },
  methods: {
    videoReadyHandler() {
      this.started = true;

      setTimeout(() => {
        this.$emit('completed');
      }, 2000)
    }
  }
}
</script>

<style lang="scss">
.landing-preloader {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: black;
  overflow: hidden;
  pointer-events: none;
  .preloader__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .preloader__video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: opacity 1s ease-out;
    will-change: opacity;
    opacity: 0;
  }
  &._started {
    .preloader__video-wrapper {
      opacity: 1;
    }
  }
}
</style>
