const slides = [
  {
    title: ['Новый',
      'Nissan Pathfinder'],
    titleClass: 'intro',
  },
  {
    title: ['Брутальный', 'и статусный'],
    description: `Дизайн нового Nissan Pathfinder подчеркивает его&nbsp;внушительные размеры&nbsp;и&nbsp;мощь`,
    titleClass: 'top bottom-left--mob top-left margin-top__minus margin-left__minus',
    descriptionClass: 'big bottom margin-bottom__minus',
  }, {
    title: ['Надежный', 'и мощный'],
    description: `Благодаря легендарному двигателю V6&nbsp;объемом 3,5 литров вы&nbsp;сможете быть во&nbsp;главе потока`,
    titleClass: 'bottom bottom-left--mob bottom-left',
    descriptionClass: 'small center',
  }, {
    title: ['Готовый к любым', 'испытаниям'],
    description: `Новая 9-ступенчатая АКПП<br>обеспечит&nbsp;плавное&nbsp;и&nbsp;быстрое<br>переключение, а&nbsp;7&nbsp;режимов вождения помогут вам<br>в&nbsp;любых условиях`,
    titleClass: 'center',
    descriptionClass: 'small bottom',
  }, {
    title: ['Удобный', 'для водителя'],
    description: `Просторный и&nbsp;комфортный для&nbsp;максимально уверенной и&nbsp;приятной поездки

 <br><br>Беспроводная зарядка позволит вам всегда&nbsp;оставаться на&nbsp;связи`,
    titleClass: 'bottom bottom-left--mob',
    descriptionClass: 'big bottom',
  }, {
    title: ['Комфортный', 'для пассажиров'],
    description: `С&nbsp;третьим рядом сидений в&nbsp;салоне новый Nissan Pathfinder готов к&nbsp;приключениям всей семьей`,
    titleClass: 'center bottom-left--mob bottom-left',
    descriptionClass: 'small small-260 bottom',
  },/* {
    title: [],
    description: ``,
    titleClass: '',
    descriptionClass: '',
  }, */{
    title: ['Современный', 'и безопасный'],
    description: `Цифровая&nbsp;12,3&Prime; приборная панель с&nbsp;функцией проекции<br>на&nbsp;лобовое стекло<br>для безопасного вождения позволит держать ситуацию под&nbsp;контролем`,
    titleClass: 'top bottom-left--mob',
    descriptionClass: 'small top',
  }, {
    title: ['Интуитивный',
      'в поездке'],
    description: `Отслеживает разметку и&nbsp;позволяет держаться в&nbsp;центре&nbsp;полосы`,
    titleClass: 'top bottom-left--mob',
    descriptionClass: 'small bottom',
  }, {
    title: [`Умный`, `в управлении`],
    description: `Подстраивает скорость, чтобы<br>соблюдать&nbsp;дистанцию до&nbsp;впереди идущего автомобиля`,
    titleClass: 'top',
    descriptionClass: 'big big-415 bottom',
  }, {
    title: ['Решительный', 'и надежный'],
    description: `Автомобиль&nbsp;может<br>затормозить&nbsp;до&nbsp;полной<br>остановки&nbsp;и&nbsp;самостоятельно<br>возобновить движение`,
    titleClass: 'top bottom-left--mob bottom-left',
    descriptionClass: 'small absolute-center',
  }, {
    title: ['Доступен', 'для предзаказа'],
    titleClass: 'top',
  },
]
export default slides
