
const PASSIVE_SUPPORTED = (() => {
	let supported = false;
	try {
		window.addEventListener(
			'test',
			null,
			Object.defineProperty({}, 'passive', {
				get: function() {
					supported = true;
				},
			})
		);
	} catch (err) {}

	return supported;
})();

const utils = {
	now() {
		if (window.performance && window.performance.now) {
			this.now = () => {
				return window.performance.now();
			};
		} else {
			this.now = () => {
				return +new Date();
			};
		}
		return this.now();
	},

	cubicProgress(value) {
		value = value < 0 ? 0 : value;
		value = value > 1 ? 1 : value;
		value /= 1 / 2;

		if (value < 1) {
			return (1 / 2) * value * value * value;
		}

		value -= 2;

		return (1 / 2) * (value * value * value + 2);
	},

	debounce(func, wait = 100, immediate = false) {
		let timeout;
		return function() {
			const context = this;
			const args = arguments;

			const later = () => {
				timeout = null;
				!immediate && func.apply(context, args);
			};

			const callNow = immediate && !timeout;
			clearTimeout(timeout);

			timeout = setTimeout(later, wait);

			callNow && func.apply(context, args);
		};
	},

	throttle(func, wait = 100) {
		let isThrottled = false;
		let savedArgs;
		let savedThis;

		function wrapper() {
			if (isThrottled) {
				savedArgs = arguments;
				savedThis = this;
			} else {
				func.apply(this, arguments);

				isThrottled = true;

				setTimeout(() => {
					isThrottled = false;
					if (savedArgs) {
						wrapper.apply(savedThis, savedArgs);
						savedArgs = savedThis = null;
					}
				}, wait);
			}
		}

		return wrapper;
	},

	passiveSupport() {
		return PASSIVE_SUPPORTED;
	},
	hasPassiveEvents: PASSIVE_SUPPORTED,
};

export default utils;
