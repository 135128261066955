<template>
  <div class="slide">
    <div class="slide__inner">
      <div class="slide__title" :class="slide.titleClass">
        <h1 class="title" ref="titlesContainer">
          <span v-for="(titlePart, i) in slide.title" :key="i"> {{ titlePart }}</span>
        </h1>
      </div>
      <div class="slide__description" :class="slide.descriptionClass" v-if="slide.description" ref="descriptionContainer">
        <p class="description" v-html="slide.description" ref="description">
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import TextSplitter from '../utils/TextSplitter'

export default {
  name: 'slideComponent',
  props: {
    slide: Object
  },
  created() {
    this.visible = false;
  },
  mounted() {
    if(this.slide.description){
      this.words = TextSplitter.split(this.$refs.description, false).words;
    }
  },
  methods: {
    show(){
      if(this.visible){
        return;
      }
      this.visible = true;

      gsap.set(this.$el, {alpha: 1, display: 'block'})

      this.$refs.titlesContainer.children.length && gsap.fromTo(this.$refs.titlesContainer.children, {y: 10, alpha: 0}, {y: 0, alpha: 1, duration: 0.75, stagger: 0.1});

      this.$refs.descriptionContainer && gsap.fromTo(this.$refs.descriptionContainer, {alpha: 0}, {alpha: 1, delay: 0.5, duration: 1})
      this.words && gsap.fromTo(this.words, {alpha: 0}, {alpha: 1, duration: 1, delay: 0.5, stagger: 0.075})
    },
    hide(){
      if(!this.visible){
        return;
      }
      this.visible = false;

      gsap.to(this.$el, {alpha: 0, duration: 1, onComplete: () => {
          this.$el.style.display = 'none'
        }})

    }
  }
}
</script>

<style lang="scss" scoped>
.slide {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  will-change: transform;
  display: none;
  &__inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    will-change: transform;
  }
  &__title {
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 140px;
    padding-top: 160px;
    padding-bottom: 170px;
    display: flex;
    span {
      display: inline-block;
      will-change: transform;
      white-space: nowrap;
    }
  }
  &__title.intro {
    position: absolute;
    top: 60%;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    .title {
      span:first-child {
        font-size: 36px;
        text-align: center;
      }
    }
  }
  &__description {
    width: 100%;
    height: 100%;
    padding-right: 140px;
    padding-bottom: 116px;
    padding-top: 170px;
    display: flex;
    justify-content: flex-end;
    line-height: 160%;
  }
}
.absolute-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.description {
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  position: relative;
  white-space: pre-line;
  &::before {
    content: '';
    width: 32px;
    height: 2px;
    background: white;
    position: absolute;
    left: -16px;
    transform: translateX(-100%);
    top: 15px;
  }
}
.title {
  font-size: 48px;
  line-height: 65px;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}



@media screen and (max-width: 768px) {
  .slide {
    &__inner {
      flex-direction: column;
    }
    &__description {
      padding-bottom: 136px;
      .description {
        max-width: 221px;
        &:before {
          width: 24px;
          height: 1.5px;
          top: 13px;
          left: -10px;
        }
      }
    }
  }
}
@media screen and (min-width: 577px) {
  .slide {
    &__title.bottom-left {
      .title {
        span:last-child {
          margin-left: 145px;
        }
      }
    }
    &__title.top-left {
      .title {
        span:first-child {
          margin-left: 145px;
        }
      }
    }
  }
}
@media screen and (min-width: 577px) {
  .slide {
    &__description {
      &.small {
        .description {
          max-width: 290px;
        }
      }
      &.small-260 {
        .description {
          max-width: 260px;
        }
      }
    }
    &__description {
      &.big {
        .description {
          max-width: 435px;
        }
      }
      &.big-415 {
        .description {
          max-width: 415px ;
        }
      }
    }
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .slide__description {
    padding-right: 70px;
  }
}
@media screen and (min-width: 576px) {
  .top {
    align-items: flex-start;
  }
  .center {
    align-items: center;
  }
  .bottom {
    align-items: flex-end;
  }
}
@media screen and (max-width: 577px) {
  .slide {
    &__title.bottom-left--mob {
      .title {
        padding-left: 74px;
        span:first-child {
          margin-left: -74px;
        }
      }
    }
  }
  .slide {
    &__title {
      padding: 110px 40px 0;
    }
    .intro {
      padding-top: 0;
      .title {
        span:first-child {
          font-size: 18px;
          text-align: center;
        }
      }
    }
    &__description {
      justify-content: center;
      padding: 0 40px 136px 114px;
      align-items: flex-end;
    }
  }
  .title {
    font-size: 26px;
    line-height: 35px;
  }
  .description {
    font-size: 12px;
    line-height: 140%;
    white-space: normal;
  }
}
@media screen and (min-width: 769px) {
  .margin-bottom__minus {
    .description {
      margin-bottom: -24px;
    }
  }
  br {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: inline;
    }
  }
}
</style>

<style>
.word-wrapper {
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
  will-change: transform;
}
.word {
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
  will-change: transform;
}
.letter {
  display: inline-block;
  will-change: transform;
}
.space {
  display: inline-block;
}
</style>
