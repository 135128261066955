<template>
  <div class="actions">
    <ActionsButton path="/configurator/pathfinder" :datalayer_params="datalayer_params.configurator" class="actions__button actions__button--primary">
    конфигуратор
    </ActionsButton>
    <ActionsButton :external="true" :datalayer_params="datalayer_params.info" path="https://www.nissan.ru/vehicles/new-vehicles/newpathfinder.html" class="actions__button actions__button--secondary">
    подробнее
    </ActionsButton>
  </div>
</template>

<script>
import gsap from 'gsap';
import ActionsButton from "@/components/ActionsButton";
export default {
  data() {
    return {
      datalayer_params: {
      configurator: {
        eventCategory: 'carsonline_landing',
        eventAction: 'configurator',
        eventLabel: '',
        Model: 'Pathfinder',
      },
      info: {
        eventCategory: 'carsonline_landing',
        eventAction: 'info',
        eventLabel: '',
        Model: 'Pathfinder',
      }
      }
    }
  },
  methods: {
    show(){
      gsap.fromTo(this.$el.children, {autoAlpha: 0, y: 10}, {autoAlpha: 1, y: 0, duration: 0.75, stagger: 0.1})
    },
    hide(immediate = false){
      gsap.to(this.$el.children, {autoAlpha: 0, duration: immediate ? 0 : 0.5})
    }
  },
  components: {
    ActionsButton
  }
}
</script>

<style lang="scss" scoped>
.actions {
  position: absolute;
  bottom: 128px;
  display: flex;
  pointer-events: all;
  left: 50%;
  transform: translateX(-50%);
}

</style>
