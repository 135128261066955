let data = {}
  const screenWidth = Math.min(window.screen.availWidth || 0, window.screen.width || 0);
  const screenHeight = Math.min(window.screen.availHeight || 0, window.screen.height || 0);
  const maxSize = Math.max(screenWidth, screenHeight);

  let videoSize = 640;

  if(maxSize >= 1280){
    videoSize = 1280
  }
  if(maxSize >= 1920){
    videoSize = 1920
  }

  data = {screenWidth, screenHeight, maxSize, videoSize};



export default data;
