import utils from './utils'

const UP = 'up';
const DOWN = 'down';
const LEFT = 'left';
const RIGHT = 'right';

class SwipeCatcher {
  constructor(element, handler, preventDefaults = true) {
    this.element = element;

    let xDistance = 50;
    let yDistance = 50;
    let swipeTime = 500;

    let touching = false;
    let startX = 0;
    let startY = 0;
    let startTime = 0;

    let directionX = 0;
    let directionY = 0;
    let prevDx = 0;
    let prevDy = 0;

    let skipX = false;
    let skipY = false;

    this.startHandler = e => {
      touching = true;
      let touch = e.touches[0];

      startX = touch.screenX;
      startY = touch.screenY;
      startTime = utils.now();

      skipX = false;
      skipY = false;
    }
    this.moveHandler = e => {
      if (touching) {
        preventDefaults && e.preventDefault();

        let touch = e.touches[0];
        let dx = touch.screenX - startX;
        let dy = touch.screenY - startY;

        let currentDirectionX = dx > prevDx ? 1 : -1;
        let currentDirectionY = dy > prevDy ? 1 : -1;

        if (preventDefaults) {
          if (Math.abs(dx) > 5 || Math.abs(dy) > 5) {
            e.preventDefault();
          }
        }

        if (directionX !== 0) {
          if (directionX !== currentDirectionX) {
            startX = touch.screenX;
            dx = 0;
          }
        }

        directionX = currentDirectionX;

        if (directionY !== 0) {
          if (directionY !== currentDirectionY) {
            startY = touch.screenY;
            dy = 0;
          }
        }

        directionY = currentDirectionY;

        prevDx = dx;
        prevDy = dy;

        const now = utils.now();
        if (now - startTime < swipeTime) {
          if (!skipX) {
            if (Math.abs(dx) > xDistance) {
              handler(dx > 0 ? RIGHT : LEFT);
              skipX = true;
            }
          }

          if (!skipY) {
            if (Math.abs(dy) > yDistance) {
              handler(dy > 0 ? DOWN : UP);
              skipY = true;
            }
          }
        }
      }
    }
    this.stopHandler = () => {
      touching = false;
      directionX = 0;
      directionY = 0;
      prevDx = 0;
      prevDy = 0;
    };

    element.addEventListener(
      'touchstart',
      this.startHandler,
      utils.hasPassiveEvents ? {
        passive: true
      } : false
    );
    document.body.addEventListener(
      'touchmove',
      this.moveHandler,
      utils.hasPassiveEvents ? {
        passive: true
      } : false
    );
    document.body.addEventListener(
      'touchend',
      this.stopHandler,
      utils.hasPassiveEvents ? {
        passive: true
      } : false
    );
    window.addEventListener('blur', this.stopHandler);
    window.addEventListener('resize', this.stopHandler);
  }
  destroy() {
    this.element.removeEventListener('touchstart', this.startHandler);
    document.body.removeEventListener('touchmove', this.moveHandler);
    document.body.removeEventListener('touchend', this.stopHandler);
    window.removeEventListener('blur', this.stopHandler);
    window.removeEventListener('resize', this.stopHandler);
  }
}

SwipeCatcher.UP = UP;
SwipeCatcher.DOWN = DOWN;
SwipeCatcher.LEFT = LEFT;
SwipeCatcher.RIGHT = RIGHT;

export default SwipeCatcher;
