<template>
  <div class="controls">
    <span class="controls__info" ref="scrollText">скрольте вниз</span>
    <ControlsButton
      class="controls__button--up"
      @click="$emit('prev')"
    />
    <ControlsButton
      class="controls__button--down"
      @click="$emit('next')"
    />
  </div>
</template>

<script>
import gsap from 'gsap';
import ControlsButton from "@/components/ControlsButton";

export default {
  name: 'ControlsComponent',
  components: {ControlsButton},
  mounted() {
    //
  },
  beforeDestroy() {
    //
  },
  methods: {
    show(){
      gsap.fromTo(this.$el.children, {x: -20, alpha: 0}, {x: 0, alpha: 1, duration: 1, ease: 'back.out', stagger: 0.15})
    },
    hideText(){
      if(!this.textHided){
        this.textHided = true;

        gsap.to(this.$refs.scrollText, {x: -10, autoAlpha: 0})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.controls {
  pointer-events: all;
  &__info {
    display: flex;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    @media (min-width: 1260px) {
      position: absolute;
      bottom: 34px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
@media screen and (max-width: 768px) {
  .controls {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    &__info {
      transform: none;
      margin-bottom: 0;
      margin-left: 16px;
      height: 48px;
    }
  }
}
</style>
