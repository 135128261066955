<template>
  <button class="controls__button"
    @click="handleClick( $event )"
  />
</template>

<script>
import gsap from 'gsap';

const MOVING = 10;
const ROTATING = 5;

const MOVING_ABS = Math.abs(MOVING);
const HALF_MOVING_ABS = MOVING_ABS / 2;

export default {
  name: 'ControlsButton',
  mounted() {
    gsap.set(this.$el, {
      transformPerspective: 300,
      'will-change': 'transform',
    });

    this.$el.addEventListener('mouseenter', this.enterHandler);
    this.$el.addEventListener('mousemove', this.moveHandler);
    this.$el.addEventListener('mouseleave', this.leaveHandler);
  },
  beforeDestroy() {
    this.$el.removeEventListener('mouseenter', this.enterHandler);
    this.$el.removeEventListener('mousemove', this.moveHandler);
    this.$el.removeEventListener('mouseleave', this.leaveHandler);
  },
  methods: {
    handleClick( e ){
      gsap.fromTo(e.target, {scale: 0.95}, {scale: 1, duration: 0.15})

      this.$emit('click', e)
    },
    enterHandler(){
      this.bBox = this.$el.getBoundingClientRect();
    },
    moveHandler(e){
      if (!this.bBox) {
        this.bBox = this.$el.getBoundingClientRect();
      }

      const x = e.clientX - (this.bBox.left - HALF_MOVING_ABS);
      const y = e.clientY - (this.bBox.top - HALF_MOVING_ABS);

      const dx = x / (this.bBox.width + MOVING_ABS) - 0.5;
      const dy = y / (this.bBox.height + MOVING_ABS) - 0.5;

      gsap.to(this.$el, {
        x: MOVING * dx,
        y: MOVING * dy,
        rotationY: ROTATING * dx,
        rotationX: -ROTATING * dy,
        duration: 0.15,
      });
    },
    leaveHandler(){
      gsap.to(this.$el, {
        x: 0,
        y: 0,
        rotationX: 0,
        rotationY: 0,
        ease: 'back.out',
        duration: 0.35,
      });
    }
  }
}
</script>

<style scoped lang="scss">
.controls__button {
  position: relative;
    width: 64px;
    height: 64px;
    background: none;
    border-radius: 50%;
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.4);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: border-color .35s ease-out;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    &::before {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover {
      border-color: white;
      transition: border-color .05s ease-out;
    }
    &--up {
      &::before {
        content: url('../assets/landing-arrow-up.svg');
        padding-bottom: 4px;
      }
    }
    &--down {
      margin-top: 20px;
      &::before {
        padding-top: 4px;
        content: url('../assets/landing-arrow.svg');
      }
    }
  }
@media screen and (max-width: 768px) {
  .controls {
    &__button {
      &--up {
        margin-left: 16px;
      }
      &:last-child {
        margin-top: 0;
      }
      width: 48px;
      height: 48px;
    }
  }
}
</style>
