<template>
  <div :class="'landing' + (started ? ' _started' : '')">
    <Preloader @completed="preloaderReadyHandler" ref="preloader" v-if="showPreloader"/>
    <div class="landing__background" ref="background">
      <div class="landing__background--images" ref="backgroundImages"></div>
    </div>
    <div class="landing__slides" ref="slidesContainer">
      <slideComponent
          class="landing__slide"
          v-for="(slide, i) in slides"
          :key="i"
          :slide="slide"
          ref="slides"
      />
    </div>
    <div class="landing__overlays">
      <Logo class="landing__logo"/>
      <ControlsComponent class="landing__controls" v-on:next="addStep(1)" @prev="addStep(-1)" ref="controls"/>
      <div class="landing__counters" ref="counters">
        <span ref="slideNumber"> {{slideNumber.toString().padStart(2, "0") }} </span>
        <span class="divider">/</span>
        <span>
          <span class="slides-counter">{{ slides.length - 1 }}</span>
        </span>
      </div>
      <Actions ref="actions"/>
    </div>
  </div>
</template>

<script>
import slides from './components/slides'
import gsap from 'gsap';
import ResourceSelector from './utils/ResourceSelector'
import SwipeCatcher from './utils/SwipeCatcher'
import WheelCatcher from './utils/WheelCatcher'
import Actions from "./components/Actions"
import Logo from "./components/Logo"
import ControlsButton from "./components/ControlsButton"
import Preloader from "./components/Preloader"
import slideComponent from "./components/slideComponent"
import ControlsComponent from "@/components/ControlsComponent";
const DEBUG = false;
const ROTATION_ANGLE = 1.5;
const MOVE_DISTANCE = 0.05;

function uai(s){return window.navigator.userAgent.toLowerCase().indexOf(s) > -1;}

const isMobile = uai('iphone') || uai('ipod') || uai('ipad') || uai('android') || uai('iemobile') || uai('blackberry') || uai('bada');

const STOP_TIMES = [
  0,
  4,
  5.35,
  9.3,
  13.3,
  27.3,
  35.3,
  43.35,
  49.3,
  55
]


export default {
  components: {ControlsComponent, ControlsButton, Actions, Logo, Preloader, slideComponent},
  layout: 'landing',
  data () {
    return {
      showPreloader: true,
      started: false,
      slideIndex: -1, // important not to set 0
      slides: false,
      preloaderReady: false,
      introReady: false,
      videosReady: false,
      // imagesReady: false,
      animating: true,
      direction: 1,
      moveRotationPower: 0,
      slideNumber: 1,
      introPlayed: false,
      allowNext: true
    }
  },
  mounted () {
    console.log('1.19');

    this.slides = slides;
    this.maxIndex = STOP_TIMES.length - 1;

    gsap.set(this.$refs.background, {transformOrigin: '50% 50%', transformPerspective: 300})

    const createVideo = (path, readyHandler = null, modifier, loop = true) => {
      const video = document.createElement('video');

      this.$refs.background.insertBefore(video, this.$refs.background.firstChild);

      video.setAttribute('muted', 'muted')
      video.setAttribute('autoplay', 'autoplay')
      video.setAttribute('playsinline', 'playsinline')
      video.className = 'background__video';

      video.muted = true
      video.autoplay = false;
      video.loop = loop

      readyHandler && video.addEventListener('canplaythrough', e => {
        readyHandler()
      });

      video.src = path;
      video.load && video.load();
      video.pause();

      gsap.set(video, {alpha: 0})

      modifier && video.classList.add(modifier)

      return video;
    }

    let readyCounter = 0;
    const checkReady = () => {
      readyCounter++;
      if(readyCounter === 2){
        this.videosReady = true
        this.checkStart()
      }
    }
    this.iVideo = createVideo(isMobile ? '/mobile/intro.mp4' : `/intro/${ResourceSelector.videoSize}.mp4`, () => {
      this.introReady = true;
      this.checkStart()
    }, DEBUG ? '_direct' : null, false);

    this.dVideo = createVideo(isMobile ? '/mobile/a.mp4' : `/background/${ResourceSelector.videoSize}_a.mp4`, checkReady, DEBUG ? '_direct' : null);
    this.rVideo = createVideo(isMobile ? '/mobile/r.mp4' : `/background/${ResourceSelector.videoSize}_r.mp4`, checkReady, DEBUG ? '_reverse' : null);

    //this.dVideo.playbackRate = 1.5;
    //this.rVideo.playbackRate = 2;

    this.dVideo.addEventListener('timeupdate', this.tickHandler)
    this.rVideo.addEventListener('timeupdate', this.tickHandler)

    this.swipeCatcher = new SwipeCatcher(this.$el, (direction) => {
      if(direction === SwipeCatcher.DOWN){
        this.addStep(-1)
      } else if(direction === SwipeCatcher.UP){
        this.addStep(1)
      }
    }, false)

    this.wheelCatcher = new WheelCatcher(this.$el, (direction) => {
      if(direction === WheelCatcher.DOWN){
        this.addStep(1)
      } else if(direction === WheelCatcher.UP){
        this.addStep(-1)
      }
    })
    gsap.ticker.add(this.tickHandler);

    this.$el.addEventListener('mousemove', this.moveHandler);

    // Preload stopframes:
    /*let imagesLoadedCounter = 0;
    this.stopFrames = [];
    const loadStopFrame = (path, index) => {
      const i = new Image();
      i.onload = () => {
        this.$refs.backgroundImages.appendChild(i);
        imagesLoadedCounter++;
        if(imagesLoadedCounter === this.maxIndex){
          this.imagesReady = true;
          this.checkStart()
        }
      }
      i.src = path;
      this.stopFrames[index] = i;
    }
    for(let k = 0; k<=this.maxIndex; k++){
      const fileIndex = (k + 1).toString().padStart(2, '0')
      const fileName = fileIndex + '_' + ResourceSelector.videoSize
      loadStopFrame(isMobile ? `/pathfinder-landing/mobile/stopframes/${fileIndex}.png` : `/pathfinder-landing/stopframes/${fileName}.png`, k);
    }*/

    this.$refs.actions.hide( true );

    document.body.addEventListener('keydown', this.keyDownHandler)
  },
  beforeDestroy() {
    this.swipeCatcher && this.swipeCatcher.destroy();
    this.wheelCatcher && this.wheelCatcher.destroy();

    gsap.ticker.remove(this.tickHandler)

    this.$el.removeEventListener('mousemove', this.moveHandler)

    document.body.removeEventListener('keydown', this.keyDownHandler)
  },
  methods: {
    keyDownHandler(e){
      if(e.keyCode === 40 || e.keyCode === 32) {
        this.addStep(1)
      } else if(e.keyCode === 38){
        this.addStep(-1)
      }
    },
    checkStart(){
      if(this.preloaderReady && this.videosReady/* && this.imagesReady*/ && this.introReady && !this.started){
        this.started = true;

        // Hide preloader
        gsap.to(this.$refs.preloader.$el, {alpha: 0, duration: 1, onComplete: () => {
            this.showPreloader = false;
          }})

        // Show intro video
        gsap.set(this.iVideo, {alpha: 1});
        this.iVideo.addEventListener('ended', e => {
          gsap.set(this.dVideo, {alpha: 1});
          this.iVideo.parentNode.removeChild(this.iVideo);

          this.introPlayed = true

          gsap.set(this.$refs.counters, {alpha: 1});
          gsap.fromTo(this.$refs.counters.children, {y: 10, alpha: 0}, {y: 0, alpha: 1, duration: 1, ease: 'back.out', stagger: 0.15})
        });
        this.nextIndex = -1;
        this.applyPause()

        gsap.to(this.$refs.background, {scale: 1 + MOVE_DISTANCE * 2, duration: 1})

        this.$refs.controls.show();

        gsap.set(this.$refs.counters, {alpha: 0})

        // gsap.set(this.stopFrames, {force3D: true});
      }
    },
    preloaderReadyHandler(){
      this.preloaderReady = true
      this.checkStart()
    },
    addStep(direction){
      if(this.animating || !this.allowNext){
        return;
      }

      if(!this.introPlayed){
        this.animating = true;
        this.iVideo.play();
        this.currentSlide.hide();

        this.stopTime = STOP_TIMES[0];
        this.nextIndex = 0;

        this.$refs.controls.hideText();

      } else {
        let newIndex = this.slideIndex + direction;

        newIndex = newIndex < 0 ? this.maxIndex - newIndex : newIndex;
        newIndex = newIndex % this.maxIndex;

        if(this.slideIndex !== newIndex){
          this.nextIndex = this.slideIndex + direction;
          this.nextIndex = this.nextIndex < 0 ? this.maxIndex : this.nextIndex;
          this.nextIndex = this.nextIndex > this.maxIndex ? 0 : this.nextIndex;

          this.stopTime = STOP_TIMES[this.nextIndex];

          this.currentSlide.hide();

          this.direction = direction;

          if(this.direction === 1){
            gsap.set(this.dVideo, {alpha: 1});
            gsap.set(this.rVideo, {alpha: DEBUG ? 0.5 : 0});

            this.rVideo.pause();
            this.dVideo.play();
          } else {
            gsap.set(this.dVideo, {alpha: DEBUG ? 0.5 : 0});
            gsap.set(this.rVideo, {alpha: 1});

            this.rVideo.play();
            this.dVideo.pause();
          }

          //gsap.set(this.stopFrames, {display: 'none'})

          this.animating = true;
        }
      }
    },

    applyPause(){
      this.slideIndex = this.nextIndex;

      this.animating = false;
      this.allowNext = false;

      setTimeout(() => {
        this.allowNext = true;
      }, 800)

      const newSlideNumber = this.slideIndex + 1;
      if(this.slideNumber !== newSlideNumber){
        this.slideNumber = newSlideNumber;
        gsap.fromTo(this.$refs.slideNumber, {alpha: 0}, {alpha: 1, duration: 0.35})
      }

      /*gsap.set(this.stopFrames, {display: 'none'})
      if(this.slideIndex >= 0){
        gsap.set(this.stopFrames[this.slideIndex], {display: 'block'});
        gsap.fromTo(this.stopFrames[this.slideIndex], {alpha: 0}, {alpha: 1, duration: 2});
      }*/

      const slide = this.$refs.slides[this.slideIndex + 1];
      if(slide){
        slide.show();
        this.currentSlide = slide;
      }

      if(this.slideIndex === this.maxIndex){
        this.$refs.actions.show();
      } else {
        this.$refs.actions.hide();
      }
    },
    tickHandler(){
      if(!this.animating || !this.introPlayed){
        return;
      }
      const accuracy = 3;

      if(this.direction === 1){
        const v = this.dVideo;
        const t = v.currentTime;

        if(t >= this.stopTime && Math.abs(t - this.stopTime) < accuracy){
          v.pause();
          v.currentTime = this.stopTime;
          this.rVideo.currentTime = this.rVideo.duration - this.stopTime;

          this.applyPause()
        }

        // console.log(t, this.stopTime, this.nextIndex)
      } else {
        const v = this.rVideo;
        const t = v.duration - v.currentTime;

        //console.log(t, this.stopTime)

        if(t <= this.stopTime && Math.abs(t - this.stopTime) < accuracy){
          v.pause();
          v.currentTime = v.duration - this.stopTime;
          this.dVideo.currentTime = this.stopTime;

          this.applyPause()
        }
      }
    },
    moveHandler( e ){
      if(!this.movePowerInited){
        this.movePowerInited = true;
        gsap.to(this, {moveRotationPower: 1, duration: 2})
      }

      let ratioX = e.clientX / window.innerWidth;
      let ratioY = e.clientY / window.innerHeight;

      ratioX = ratioX < 0 ? 0 : ratioX;
      ratioX = ratioX > 1 ? 1 : ratioX;

      ratioY = ratioY < 0 ? 0 : ratioY;
      ratioY = ratioY > 1 ? 1 : ratioY;

      ratioX -= 0.5;
      ratioY -= 0.5;

      gsap.to(this.$refs.background, {
        rotationX: ratioY * ROTATION_ANGLE * this.moveRotationPower,
        rotationY: ratioX * ROTATION_ANGLE * this.moveRotationPower,
        xPercent: -ratioX * MOVE_DISTANCE * this.moveRotationPower * 100,
        yPercent: -ratioY * MOVE_DISTANCE * this.moveRotationPower * 100,
        force3D: true,
        duration: 3
      })
      gsap.to(this.$refs.slidesContainer, {
        xPercent: -ratioX / 1.5 * MOVE_DISTANCE * this.moveRotationPower * 100,
        yPercent: -ratioY / 1.5 * MOVE_DISTANCE * this.moveRotationPower * 100,
        force3D: true,
        duration: 3
      })
    }
  }
}
</script>

<style lang="scss">
html {
  overflow: hidden;
}
$font-name: 'Nissan';

@font-face {
  font-family: $font-name;
  src: url('../public/nissanonline-regular-webfont.eot');
  src: url('../public/nissanonline-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../public/nissanonline-regular-webfont.woff2') format('woff2'),
  url('../public/nissanonline-regular-webfont.woff') format('woff'),
  url('../public/nissanonline-regular-webfont.ttf') format('truetype'),
  url('../public/nissanonline-regular-webfont.svg#nissan_brandregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
.landing {
  font-family: 'Nissan';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  cursor: default;
  overflow: hidden;
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    overflow: hidden;
    pointer-events: none;
    .background__video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      &._direct {
        width: 50%;
      }
      &._reverse {
        left: 50%;
        width: 50%;
      }
    }
    .landing__background--images{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: none;
      }
    }
  }
  &__background, &__slides, &__overlays {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: opacity 1s ease-out;
    will-change: opacity;
    opacity: 0;
  }
  &__overlays, &__slides {
    pointer-events: none;
  }
  &._started {
    .landing__background, .landing__slides, .landing__overlays {
      opacity: 1;
    }
  }
  &__logo {
    position: fixed;
    top: 30px;
    left: 24px;
  }
  &__controls {
    position: fixed;
    bottom: 40px;
    left: 32px;
    @media (min-width: 1260px) {
      left: 0px;
      right: 0;
      padding-left: 32px;
    }
  }
  &__cta {
    position: fixed;
    right: 36px;
    top: 35px;
  }
  &__counters {
    position: fixed;
    bottom: 50px;
    right: 41px;
    color: white;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.05em;
    user-select: none;
    .slides-counter {
      opacity: .5;
    }
    span {
      display: inline-block;
      will-change: transform;
    }
  }
}
@media screen and (max-width: 768px) {
  .landing {
    &__controls {
      bottom: 31px;
      left: 16px;
    }
    &__counters {
      font-size: 12px;
      line-height: 160%;
      bottom: 45px;
      right: 26px;
    }
    &__logo {
      top: 17px;
      left: 16px;
    }
  }
}
</style>
