import utils from './utils'

const UP = 'up';
const DOWN = 'down';

const FF_MODE = window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

const settings = {
  length: 149,
  difference: 200,
  end: 10,
  middle: 70,
  duration: 1250,
};

class WheelCatcher {
  constructor(element, handler, preventDefaults = true) {
    this.element = element;
    let scrollings = [];
    let prevScroll = 0;
    let prevCallTime = -1;

    this.wheelHandler = e => {
      if(!utils.hasPassiveEvents){
        preventDefaults && e.preventDefault();
      }

      const now = utils.now();
      e = window.event || e || e.originalEvent;

      let value = e.wheelDelta || -e.deltaY || -e.detail;

      let delta = Math.max(-1, Math.min(1, value));

      if (FF_MODE) {
        delta = -Math.max(-1, Math.min(1, e.deltaY));
      }
      if (scrollings.length > settings.length) {
        scrollings.shift();
      }

      scrollings.push(Math.abs(value));

      let timeDifference = now - prevScroll;

      prevScroll = now;

      if (timeDifference > settings.difference) {
        scrollings = [];
      }

      let averageEnd = this._getAverageScrolling(settings.end, scrollings);
      let averageMiddle = this._getAverageScrolling(settings.middle, scrollings);

      if (averageEnd >= averageMiddle && now - prevCallTime > settings.duration) {
        scrollings = [];
        prevCallTime = now;
        handler && handler(delta > 0 ? UP : DOWN, e);
      }
    };

    element.addEventListener(
      'mousewheel',
      this.wheelHandler,
      utils.hasPassiveEvents ? {
        passive: true
      } : false
    );
    element.addEventListener(
      'wheel',
      this.wheelHandler,
      utils.hasPassiveEvents ? {
        passive: true
      } : false
    );
  }
  destroy() {
    this.element.removeEventListener(
      'mousewheel',
      this.wheelHandler
    );
    this.element.removeEventListener(
      'wheel',
      this.wheelHandler
    );
  }
  _getAverageScrolling(count, scrollings) {
    let result = 0;
    let lastElements = scrollings.slice(Math.max(scrollings.length - count, 1));
    for (let k = 0; k < lastElements.length; k++) {
      result += lastElements[k];
    }
    return Math.ceil(result / count);
  }
}

WheelCatcher.UP = UP;
WheelCatcher.DOWN = DOWN;

export default WheelCatcher
