<template>
  <!-- eslint-disable -->
  <a :to="path" class="actions-button" v-if="!external" >
    <slot />
  </a>
  <a :href="path" v-else class="actions-button"
  >
    <slot />
  </a>
</template>

<script>
import gsap from 'gsap';

const MOVING = 10;
const ROTATING = 5;

const MOVING_ABS = Math.abs(MOVING);
const HALF_MOVING_ABS = MOVING_ABS / 2;

export default {
  props: ['path', 'external', 'datalayer_params'],
  mounted() {
    gsap.set(this.$el, {
      transformPerspective: 300,
      'will-change': 'transform',
    });

    this.$el.addEventListener('mouseenter', this.enterHandler);
    this.$el.addEventListener('mousemove', this.moveHandler);
    this.$el.addEventListener('mouseleave', this.leaveHandler);
  },
  beforeDestroy() {
    this.$el.removeEventListener('mouseenter', this.enterHandler);
    this.$el.removeEventListener('mousemove', this.moveHandler);
    this.$el.removeEventListener('mouseleave', this.leaveHandler);
  },
  methods: {
    handleClick( e, type ) {
      gsap.fromTo(e.target, {scale: 0.95}, {scale: 1, duration: 0.15})
      this.$emit('click', e)
    },
    enterHandler(e){
      this.bBox = this.$el.getBoundingClientRect();
    },
    moveHandler(e){
      if (!this.bBox) {
        this.bBox = this.$el.getBoundingClientRect();
      }

      const x = e.clientX - (this.bBox.left - HALF_MOVING_ABS);
      const y = e.clientY - (this.bBox.top - HALF_MOVING_ABS);

      const dx = x / (this.bBox.width + MOVING_ABS) - 0.5;
      const dy = y / (this.bBox.height + MOVING_ABS) - 0.5;

      gsap.to(this.$el, {
        x: MOVING * dx,
        y: MOVING * dy,
        rotationY: ROTATING * dx,
        rotationX: -ROTATING * dy,
        duration: 0.15,
      });
    },
    leaveHandler(e){
      gsap.to(this.$el, {
        x: 0,
        y: 0,
        rotationX: 0,
        rotationY: 0,
        ease: 'back.out',
        duration: 0.35,
      });
    }
  }
}
</script>

<style lang="scss">
.actions {
  &__button {
    position: relative;
    height: 40px;
    width: 180px;
    letter-spacing: 0.1em;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 100%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.5s ease-out;
    border: 1.4px solid #FFFFFF;
    &:hover {
      transition: background-color 0.05s ease-out
    }
    &--primary {
      color: #000000;
      background: #FFFFFF;
      margin-right: 20px;
      &:hover {
        color: #FFFFFF;
        background: none;
      }
    }
    &--secondary {
      color: #FFFFFF;
      &:hover {
        color: #000000;
        background: #FFFFFF;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .actions {
    flex-direction: column;
    align-items: center;
    &__button {
      width: 160px;
      height: 30px;
      font-size: 10px;
      border-width: 1px;
      &--primary {
        margin-right: 0;
        margin-bottom: 15px;
      }
      &--secondary {
      }
    }
  }
}
</style>
