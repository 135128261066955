<template>
  <a href="/" class="landing__logo"></a>
</template>

<script>
export default {

}
</script>

<style>
.landing__logo {
  pointer-events: all;
}
@media screen and (min-width: 577px) {
  .landing__logo::before {
    content: url('../assets/logo.svg');
  }
}
@media screen and (max-width: 576px) {
  .landing__logo::before {
    content: url('../assets/logo-mob.svg');
  }
}
</style>
